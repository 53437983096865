import { getAsync, postAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { TokenExpiryWrapper } from './TokenManager'
import {
  IAppInsightsData,
  IAppInsightsPublishedResponse,
  IAppInsightsRequest,
  IUniqueUsersByCountryResponse,
  IUniqueUsersResponse,
} from '../models/api/IApplicationInsights'

export interface IAppVersion {
  version: string
}

export interface IFlagFilters {
  name: string
  parameters: {
    [y: string]: string
  }
}

export interface IFlagDetails {
  enabled: boolean
  client_filters?: IFlagFilters[]
}

export interface IAppFlags {
  [x: string]: IFlagDetails
}

const FAIL_VERSION_RESPONSE = { success: false, data: { version: '0.0.0' } }
const FAIL_FLAGS_RESPONSE = { success: false, data: {} }

export const getAppVersion = async (): Promise<IResponse<IAppVersion>> => {
  try {
    const response = await fetch('/v1/app/version')
    if (response.ok) return await response.json()
    else return FAIL_VERSION_RESPONSE
  } catch {
    return FAIL_VERSION_RESPONSE
  }
}

export const getAppFlags = async (): Promise<IResponse<IAppFlags>> => {
  try {
    const response = await fetch('/v1/app/flags')
    if (response.ok) return await response.json()

    return FAIL_FLAGS_RESPONSE
  } catch {
    return FAIL_FLAGS_RESPONSE
  }
}

export interface IFocusData {
  [x: string]: { label: string; options: string[] }
}

export const getSubmissionInsights = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<IAppInsightsData>> => {
    return getAsync(`/v1/app/insights/submissions`, request)
  },
  [],
  null,
)

export const getDownloadsInsights = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<IAppInsightsData>> => {
    return getAsync(`/v1/app/insights/downloads`, request)
  },
  [],
  null,
)

export const getUniqueUsers = TokenExpiryWrapper(
  async (request: IAppInsightsRequest): Promise<IResponse<IUniqueUsersResponse>> => {
    return getAsync(`/v1/app/insights/users/unique`, request)
  },
  [],
  null,
)

export const getUniqueUsersByCountry = TokenExpiryWrapper(
  async (request: IAppInsightsRequest): Promise<IResponse<IUniqueUsersByCountryResponse[]>> => {
    return postAsync(`/v1/app/insights/users/country`, request)
  },
  [],
  null,
)

export const getPublishedInsights = TokenExpiryWrapper(
  async (request: IAppInsightsRequest): Promise<IResponse<IAppInsightsPublishedResponse>> => {
    return getAsync(`/v1/app/insights/published`, request)
  },
  [],
  null,
)

export const exportUniqueUsersByCountryInsights = TokenExpiryWrapper(
  async (request: IAppInsightsRequest): Promise<IResponse<string>> => {
    return postAsync(`/v1/app/export/insights/users/country`, request)
  },
  [],
  null,
)

export const exportDownloadsInsights = TokenExpiryWrapper(
  async (request: IAppInsightsRequest): Promise<IResponse<string>> => {
    return postAsync(`/v1/app/export/insights/downloads`, request)
  },
  [],
  null,
)

export const exportSubmissionsInsights = TokenExpiryWrapper(
  async (request: IAppInsightsRequest): Promise<IResponse<string>> => {
    return postAsync(`/v1/app/export/insights/submissions`, request)
  },
  [],
  null,
)
