import { Status, Tooltip } from '@aurecon-creative-technologies/styleguide'
import { AppRoleName } from '../../enums/AppRoles'
import { AutomationApprovalDisplayStates } from '../../enums/AutomationApprovalStates'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'
import { IAutomationApproval } from '../../models/api/IManageAutomationTableRow'
import Style from '../../styles/Manage.module.sass'
import { AutomationStateNames } from '../../enums/AutomationStates'
import { StatusIconColours } from '../../config/config'

interface IGetStatusIcon {
  automation: Partial<IGetAutomationResponse>
  appRole: string
  size?: 'extra small' | 'small' | 'medium' | 'large'
  showApproverRole?: boolean
  showApproverName?: boolean
}

interface IApprovalStatus extends Pick<IGetStatusIcon, 'appRole' | 'size' | 'showApproverRole'> {
  hasApprovalState: IAutomationApproval
}

const ApprovalStatus = (props: IApprovalStatus) => {
  const { hasApprovalState, appRole, size, showApproverRole } = props
  switch (hasApprovalState.ApprovalState.Name) {
    case AutomationApprovalDisplayStates.ActionRequired:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.ActionRequired}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.ActionRequired}`
              : AutomationApprovalDisplayStates.ActionRequired
          }
        />
      )
    case AutomationApprovalDisplayStates.Approved:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          colour={StatusIconColours.Approved}
          size={size}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.Approved}`
              : AutomationApprovalDisplayStates.Approved
          }
        />
      )

    case AutomationApprovalDisplayStates.Rejected:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.Rejected}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.Rejected}`
              : AutomationApprovalDisplayStates.Rejected
          }
        />
      )

    case AutomationApprovalDisplayStates.AwaitingApproval:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.AwaitingApproval}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.AwaitingApproval}`
              : AutomationApprovalDisplayStates.AwaitingApproval
          }
        />
      )

    case AutomationApprovalDisplayStates.AcceptedForTracking:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.AcceptedForTracking}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.AcceptedForTracking}`
              : AutomationApprovalDisplayStates.AcceptedForTracking
          }
        />
      )

    default:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.Deactivated}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.Deactivated}`
              : AutomationApprovalDisplayStates.Deactivated
          }
        />
      )
  }
}

export const StatusIcon = (props: IGetStatusIcon) => {
  const { automation, appRole, size, showApproverRole, showApproverName } = props
  const iconSize = size ?? 'small'
  if (automation.AutomationState?.Name === AutomationStateNames.DRAFT)
    return (
      <Status
        cssClass={Style.approvalStatus}
        type='success'
        size={iconSize}
        colour={StatusIconColours.Draft}
        label={AutomationApprovalDisplayStates.Draft}
      />
    )
  const approvals = automation.AutomationApprovals as IAutomationApproval[]

  const latestApproval = [...approvals].sort(
    (a, b) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime(),
  )[0]

  const hasApprovalState = [...approvals]
    .sort((a, b) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime())
    .find((approval) => approval.ApproverRole.Name === appRole)

  if (
    latestApproval?.ApprovalState?.Name === AutomationApprovalDisplayStates.Rejected &&
    latestApproval?.ApproverRole?.Name !== appRole
  ) {
    return (
      <Status
        cssClass={Style.approvalStatus}
        type='success'
        size={iconSize}
        colour={StatusIconColours.Deactivated}
        label={
          showApproverRole
            ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.NotApplicable}`
            : AutomationApprovalDisplayStates.NotApplicable
        }
      />
    )
  }

  if (!hasApprovalState)
    return (
      <Status
        cssClass={Style.approvalStatus}
        type='success'
        size={iconSize}
        colour={StatusIconColours.AwaitingApproval}
        label={
          showApproverRole
            ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.AwaitingApproval}`
            : AutomationApprovalDisplayStates.AwaitingApproval
        }
      />
    )

  return showApproverName ? (
    <Tooltip show={hasApprovalState.ApprovedBy.UserFullName} defaultUp padding={25} cssClass='tooltip-theme-dark'>
      <ApprovalStatus
        appRole={appRole}
        hasApprovalState={hasApprovalState}
        size={iconSize}
        showApproverRole={showApproverRole}
      />
    </Tooltip>
  ) : (
    <ApprovalStatus
      appRole={appRole}
      hasApprovalState={hasApprovalState}
      size={iconSize}
      showApproverRole={showApproverRole}
    />
  )
}
