import React, { useEffect, useState } from 'react'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Button, Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'
import { PublishedInsights } from '../../stores/AppStore'
import { useRecoilValueLoadable } from 'recoil'
import AppInsightsLineChart, { ILineChartData } from './AppInsightsLineChart'

const THREE_MONTH_DATA = 1
const SIX_MONTH_DATA = 2
const dropdownItems: IDropdownItemProps[] = [
  { id: THREE_MONTH_DATA, label: '3 months' },
  { id: SIX_MONTH_DATA, label: '6 months' },
]
const MAX_MONTH_PUBLISHED_DATA = 6
const MIN_MONTH_PUBLISHED_DATA = 3

export interface IPublishedAutomations {
  name: string
  value: number
}

const PublishedAutomations: React.FC = () => {
  const canDownloadInsights = useUserPermission(actions.DOWNLOAD_INSIGHTS)
  const publishedInsights = useRecoilValueLoadable(PublishedInsights)
  const [publishedAutomations, setPublishedAutomations] = useState<IPublishedAutomations[]>([])
  const [lineData, setLineData] = useState<ILineChartData[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<number>(0)
  const [dropdownData, setDropdownData] = useState<IDropdownItemProps[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  useEffect(() => {
    if (publishedInsights.state !== 'hasValue' || !publishedInsights.contents) return
    const mappedData = publishedInsights.contents.monthData.map((data) => {
      return {
        name: data.month,
        value: data.count,
      }
    })

    setPublishedAutomations(mappedData)
    setTotalCount(publishedInsights.contents.totalCount)

    if (mappedData.length >= MAX_MONTH_PUBLISHED_DATA) {
      setLineData(mappedData.slice(-MAX_MONTH_PUBLISHED_DATA))
      setDropdownData(dropdownItems)
      setSelectedDateRange(SIX_MONTH_DATA)
    } else {
      setLineData(mappedData.slice(-MIN_MONTH_PUBLISHED_DATA))
      setDropdownData(dropdownItems.filter((item) => item.id === THREE_MONTH_DATA))
      setSelectedDateRange(THREE_MONTH_DATA)
    }
  }, [publishedInsights.contents, publishedInsights.state])

  const handleDateRangeChange = (value: string | number) => {
    if (value === SIX_MONTH_DATA) {
      setLineData(publishedAutomations.slice(-MAX_MONTH_PUBLISHED_DATA))
      setSelectedDateRange(SIX_MONTH_DATA)
    } else {
      setLineData(publishedAutomations.slice(-MIN_MONTH_PUBLISHED_DATA))
      setSelectedDateRange(THREE_MONTH_DATA)
    }
  }

  return (
    <div className={PageStyle.publishedAutomationsContainer}>
      <div className={PageStyle.publishedAutomationsHeader}>
        <div className={PageStyle.publishedAutomationstitle}>Published Automations</div>
        {canDownloadInsights && <Button cssClass={PageStyle.downloadButton} type='icon-round' icon='file_download' />}
      </div>
      <div className={PageStyle.uniqueAndPublishedActionContainer}>
        <div className={PageStyle.sinceLaunchedContainer}>
          <div className={PageStyle.sinceLaunchedCount}> {totalCount ?? 0} </div>
          <span className={PageStyle.sinceLaunchedLabel}>Total since launched</span>
        </div>
        <div className={PageStyle.dateRange}>
          <div className={PageStyle.dateRangeTitle}> Date Range </div>
          <Dropdown
            items={dropdownData}
            selectedItem={selectedDateRange}
            onSelectItem={(data) => handleDateRangeChange(data)}
            cssClass={PageStyle.dateRangeDropdown}
          />
        </div>
      </div>
      <div className={PageStyle.publishedAutomationsGraphContainer}>
        <AppInsightsLineChart isLoading={publishedInsights.state === 'loading'} lineData={lineData} />
      </div>
    </div>
  )
}

export default PublishedAutomations
