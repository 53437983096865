import { FC } from 'react'
import { Container, Loader } from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import PageStyle from '../styles/ApplicationInsights.module.sass'
import { appInsights } from '../api/AppInsights'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import classNames from 'classnames'
import Submissions from '../components/ApplicationInsights/Submissions'
import { useRecoilValueLoadable } from 'recoil'
import { DownloadInsights, SubmissionsInsights, UniqueUsersInsights } from '../stores/AppStore'
import Downloads from '../components/ApplicationInsights/Downloads'
import UniqueUsers from '../components/ApplicationInsights/UniqueUsers'
import PublishedAutomations from '../components/ApplicationInsights/PublishedAutomations'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'
import ErrorPage from './ErrorPage'

const ApplicationInsights: FC = () => {
  const canViewApplicationInsights = useUserPermission(actions.VIEW_APPLICATION_INSIGHTS)
  const canViewSubmissionsInsights = useUserPermission(actions.VIEW_SUBMISSIONS_INSIGHTS)
  const canViewSinceLaunchedInsights = useUserPermission(actions.VIEW_SINCE_LAUNCHED_INSIGHTS)
  const canViewDownloadsInsights = useUserPermission(actions.VIEW_DOWNLOADS_INSIGHTS)
  const canViewUniqueUsersInsights = useUserPermission(actions.VIEW_UNIQUE_USERS_INSIGHTS)
  const canViewPublishedAutomations = useUserPermission(actions.VIEW_PUBLISHED_AUTOMATIONS)

  const submissionInsights = useRecoilValueLoadable(SubmissionsInsights)
  const downloadInsights = useRecoilValueLoadable(DownloadInsights)
  const totalUsersSinceLaunched = useRecoilValueLoadable(UniqueUsersInsights({}))

  if (!canViewApplicationInsights) {
    return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />
  }

  if (appInsights) appInsights.trackPageView({ name: 'ApplicationInsights' })

  const totalSubmissionsClasses = classNames({
    [PageStyle.sinceLaunchedItem]: true,
    [PageStyle.totalSubmission]: true,
  })

  const totalDownloadsClasses = classNames({
    [PageStyle.sinceLaunchedItem]: true,
    [PageStyle.totalDownloads]: true,
  })

  const uniqueUsersClasses = classNames({
    [PageStyle.sinceLaunchedItem]: true,
    [PageStyle.uniqueUsers]: true,
  })

  return (
    <Page menu>
      <Container cssClass={PageStyle.layoutContainer} fluid>
        <header className={PageStyle.header}>
          <h2 className={PageStyle.pageTitlte}>Application Insights</h2>
        </header>
        {canViewSinceLaunchedInsights && (
          <div className={PageStyle.sinceLaunchedContainer}>
            <div className={PageStyle.sinceLaunchedTitle}>Since Launched</div>
            <div className={PageStyle.sinceLaunchedContent}>
              <div className={totalSubmissionsClasses}>
                <h4 className={PageStyle.sinceLaunchedItemTitle}>Total Submissions</h4>
                <p className={PageStyle.sinceLaunchedItemValue}>
                  {submissionInsights.state === 'loading' ? (
                    <Loader size='small' />
                  ) : (
                    submissionInsights?.contents.totalCount ?? 0
                  )}
                </p>
              </div>
              <div className={totalDownloadsClasses}>
                <h4 className={PageStyle.sinceLaunchedItemTitle}>Total Downloads</h4>
                <p className={PageStyle.sinceLaunchedItemValue}>
                  {downloadInsights.state === 'loading' ? (
                    <Loader size='small' />
                  ) : (
                    downloadInsights?.contents.totalCount ?? 0
                  )}
                </p>
              </div>
              <div className={uniqueUsersClasses}>
                <h4 className={PageStyle.sinceLaunchedItemTitle}>Unique Users</h4>
                <p className={PageStyle.sinceLaunchedItemValue}>
                  {totalUsersSinceLaunched.state === 'loading' ? (
                    <Loader size='small' />
                  ) : (
                    totalUsersSinceLaunched.contents?.data?.[0]?.uniqueUsers ?? 0
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        {canViewSubmissionsInsights && (
          <>
            <div className={PageStyle.divider} />
            <Submissions submissionInsightsLoadable={submissionInsights} />
          </>
        )}

        {canViewDownloadsInsights && (
          <>
            <div className={PageStyle.divider} />
            <Downloads downloadInsightsLoadable={downloadInsights} />
          </>
        )}

        {(canViewUniqueUsersInsights || canViewPublishedAutomations) && <div className={PageStyle.divider} />}
        <div className={PageStyle.uniqueAndPublishedContainer}>
          {canViewUniqueUsersInsights && <UniqueUsers />}
          {canViewPublishedAutomations && <PublishedAutomations />}
        </div>
      </Container>
    </Page>
  )
}

export default ApplicationInsights
