import { FC, useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { appInsights } from '../api/AppInsights'
import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'
import Hero from '../components/Hero'
import Page from '../components/Page'
import FeaturedCard from '../components/FeaturedCard'
import AutomationCard from '../components/AutomationCard'
import { dateToString, handleHorizantalScroll, indexKey } from '../helpers/utils'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { AppRoutes } from '../enums/AppRoutes'
import { useRecoilValueLoadable } from 'recoil'
import { RecommendedAutomations } from '../stores/ScriptLibraryStore'
import { TagTypes } from '../enums/TagTypes'
import LoadingScreen from '../components/LoadingScreen'
import { SCRIPT_LIBRARY_VIEW_URL } from '../config/config'
import { FeaturedAutomations, HeroAutomation } from '../enums/TemporaryFeatured'
import { ITagProps } from '../components/Tag'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'

import Style from '../styles/Discover.module.sass'
import { useUserPermission } from '../hooks/useUserPermission'
import { actions } from '../helpers/userPermission'
import ErrorPage from './ErrorPage'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'

const Discover: FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { user } = useAuth0()
  const recommendedAutomationsResult = useRecoilValueLoadable(RecommendedAutomations)
  const [recommendedAutomations, setRecommendedAutomations] = useState<IGetAutomationResponse[]>([])

  const canViewHomePage = useUserPermission(actions.VIEW_ATC_HOME_PAGE)

  useEffect(() => {
    if (recommendedAutomationsResult.state === 'loading') return

    if (recommendedAutomationsResult.state === 'hasValue') {
      setRecommendedAutomations(recommendedAutomationsResult.contents ?? [])
      return
    }

    setRecommendedAutomations([])
  }, [recommendedAutomationsResult])

  const handleAutomationCardClick = (id: string, uploadTypeId: number | null) => {
    if (appInsights) appInsights.trackEvent({ name: 'Recommendation Clicked' })

    if (uploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY) {
      location.hash = `#/${AppRoutes.VIEW_AUTOMATION}/${id}/${AutomationUploadTypes.SCRIPT_LIBRARY}`
      return
    }
    if (uploadTypeId === AutomationUploadTypes.STREAMLINER) {
      location.hash = `#/${AppRoutes.VIEW_AUTOMATION}/${id}/${AutomationUploadTypes.STREAMLINER}`
      return
    }

    location.hash = `#/${AppRoutes.VIEW_AUTOMATION}/${id}`
  }

  const handleFeaturedClick = (automationId: string) => {
    location.hash = `#/${AppRoutes.FEATURED}/${automationId}`
  }

  if (appInsights) appInsights.trackPageView({ name: 'Discover' })

  if (!canViewHomePage) return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />

  return (
    <Page menu>
      <Container cssClass={Style.layoutContainer} fluid>
        <Hero firstName={user?.nickname?.split('.')[0]} automation={HeroAutomation} />

        <Grid row xs={12} gap={12} cssClass={Style.section}>
          {FeaturedAutomations.map((automation) => {
            const capabilities: ITagProps[] =
              automation.AutomationCapabilities?.map((capability) => ({
                id: capability.Id.toString(),
                type: TagTypes.CAPABILITY,
                label: capability.Name.toString(),
              })) ?? []

            const sources: ITagProps[] =
              automation.AutomationSources?.map((source) => ({
                id: source.Id.toString(),
                type: TagTypes.SOURCE,
                label: source.Name.toString(),
              })) ?? []

            return (
              <Grid item right gap={1} xl={6} cssClass={Style.featuredItem} key={indexKey()}>
                <FeaturedCard
                  title={automation.Title}
                  id={automation.Id}
                  description={automation.Description}
                  creationDate={dateToString(automation.DateCreated)}
                  tags={[...sources, ...capabilities]}
                  onClick={() => handleFeaturedClick(automation.Id)}
                  thumbnail={automation.ThumbnailUrl ?? automation.Id}
                />
              </Grid>
            )
          })}
        </Grid>

        <div className={Style.divider} />

        <Grid row xs={12} cssClass={Style.section}>
          <Grid item xs={12} sm={12} md={12} cssClass={Style.sectionTitle}>
            <h2>RECOMMENDED FOR YOU</h2>
            <div className={Style.scrollButtonsContainer}>
              <Button
                onClick={() =>
                  handleHorizantalScroll({
                    element: scrollRef.current!,
                    distance: 250,
                    direction: 'left',
                  })
                }
                type='icon-round-secondary'
                size='small'
                icon='keyboard_arrow_left'
                cssClass={Style.scrollButtons}
                disabled={recommendedAutomations.length === 0}
              />
              <Button
                onClick={() =>
                  handleHorizantalScroll({
                    element: scrollRef.current!,
                    distance: 250,
                    direction: 'right',
                  })
                }
                type='icon-round-secondary'
                size='small'
                icon='keyboard_arrow_right'
                cssClass={Style.scrollButtons}
                disabled={recommendedAutomations.length === 0}
              />
            </div>
          </Grid>
          <div className={Style.recommendations} ref={scrollRef}>
            {recommendedAutomationsResult.state === 'loading' && (
              <div className={Style.recommendationLoader}>
                <LoadingScreen size='extra small' text='Loading recommended automations' />
              </div>
            )}
            {recommendedAutomations.length > 0 &&
              recommendedAutomations?.map((automation: IGetAutomationResponse) => {
                const isScriptLibrary = Number(automation.UploadTypeId) === AutomationUploadTypes.SCRIPT_LIBRARY
                let viewLink
                if (isScriptLibrary) {
                  viewLink = `${SCRIPT_LIBRARY_VIEW_URL}/${automation?.Id}`
                } else if (automation.AutomationUrl?.Url) {
                  viewLink = automation?.AutomationUrl?.Url
                }

                return (
                  <Grid item xs={12} sm={6} md={6} lg={3} key={automation.Id} cssClass={Style.recommendedItem}>
                    <AutomationCard
                      id={automation.Id}
                      title={automation.Title}
                      description={automation.Description}
                      creationDate={dateToString(automation.DateCreated)}
                      tags={
                        [
                          ...(automation.AutomationCapabilities?.map((capability) => ({
                            id: capability.Id.toString(),
                            type: TagTypes.CAPABILITY,
                            label: capability.Name,
                          })) ?? []),
                          ...(automation.AutomationSources?.map((source) => ({
                            id: source.Id.toString(),
                            type: TagTypes.SOURCE,
                            label: source.Name,
                          })) ?? []),
                        ] as ITagProps[]
                      }
                      viewLink={viewLink}
                      uploadType={automation.UploadTypeId}
                      onClick={() => handleAutomationCardClick(automation.Id, automation.UploadTypeId)}
                      thumbnail={automation.ThumbnailUrl ?? automation.Id}
                    />
                  </Grid>
                )
              })}
            {recommendedAutomationsResult.state !== 'loading' && recommendedAutomations.length === 0 && (
              <div className={Style.recommendationLoader}>
                <h3>No recommendations found</h3>
              </div>
            )}
          </div>
        </Grid>
      </Container>
    </Page>
  )
}

export default Discover
