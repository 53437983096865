import { FC, useEffect } from 'react'
import Style from '../../styles/Manage.module.sass'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  DeactivatedAutomations,
  ManagePageCount,
  MyContributions,
  PendingApprovalList,
} from '../../stores/ManageAutomationStore'
import { Button, IHeader, Pill, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { AutomationSources } from '../../enums/AutomationSources'
import { dateToString } from '../../helpers/utils'
import { AppRolesEnum, PAGE_SIZE } from '../../config/config'
import { IManageAutomationTableRow } from '../../models/api/IManageAutomationTableRow'
import { AutomationStateNames } from '../../enums/AutomationStates'
import { AppRoutes } from '../../enums/AppRoutes'
import LoadingScreen from '../LoadingScreen'
import { TabsEnum } from '../../enums/Manage'
import { StatusIcon } from './StatusIcon'
import { useAuth0 } from '@auth0/auth0-react'
import { TagTypes } from '../../enums/TagTypes'
import { SearchAutomationFilters } from '../../stores/AppStore'
import { actions } from '../../helpers/userPermission'
import { useUserPermission } from '../../hooks/useUserPermission'

interface IManageAutomationsTableProps {
  currentPage: number
  type: TabsEnum
}

interface IOpenButtonProps {
  automation: IManageAutomationTableRow
}

const OpenButton: FC<IOpenButtonProps> = (props) => {
  const { automation } = props
  const { user } = useAuth0()
  const isDraft = automation.AutomationState.Name === AutomationStateNames.DRAFT
  const isOwner = user?.email === automation.AutomationAuthor?.UserEmail
  const isEdit = isDraft && isOwner

  const canEditAutomation = useUserPermission(actions.EDIT_AUTOMATION)
  const canViewAutomationDetails = useUserPermission(actions.VIEW_AUTOMATION_DETAILS)

  const handleOpenClick = () => {
    if (isEdit) {
      location.hash = `#/${AppRoutes.UPLOAD_AUTOMATION}/${automation.Id}`
      return
    }
    location.hash = `#/${AppRoutes.VIEW_AUTOMATION}/${automation.Id}`
  }

  return (
    <Button
      type='default'
      size='small'
      icon='open_in_new'
      disabled={(isEdit && !canEditAutomation) || (!isEdit && !canViewAutomationDetails)}
      onClick={handleOpenClick}
    />
  )
}

const ManageAutomationsTable: FC<IManageAutomationsTableProps> = (props) => {
  const { currentPage, type } = props
  const dataStoreMapping = {
    [TabsEnum.APPROVALS]: PendingApprovalList,
    [TabsEnum.MY_CONTRIBUTIONS]: MyContributions,
    [TabsEnum.DEACTIVATED_AUTOMATIONS]: DeactivatedAutomations,
  }
  const dataStore = dataStoreMapping[type]
  const isDeactivateList = type === TabsEnum.DEACTIVATED_AUTOMATIONS
  const automationList = useRecoilValueLoadable(dataStore)
  const setManagePageCount = useSetRecoilState(ManagePageCount)
  const setSearchAutomationFilters = useSetRecoilState(SearchAutomationFilters)

  const headers: IHeader[] = [
    { label: 'Automation Title', width: 334 },
    { label: 'Technical Authoriser' },
    { label: 'IT Authoriser' },
    { label: 'Digital Authoriser' },
    { label: 'Source', align: 'left' },
    { label: isDeactivateList ? 'Deactivation Date' : 'Date of Creation' },
    { label: '' },
  ]

  useEffect(() => {
    if (!automationList || automationList.state !== 'hasValue' || !automationList.contents) {
      return
    }

    const pageCount = automationList.contents.length / PAGE_SIZE
    setManagePageCount(Math.ceil(pageCount > 0 ? pageCount : 1))
  }, [automationList, setManagePageCount])

  if (automationList.state === 'loading') {
    return <LoadingScreen size='extra small' text='Loading automations' />
  }

  const handlePillClick = (id: string) => {
    const label = AutomationSources.find((x) => x.key === id)?.value
    setSearchAutomationFilters({
      [TagTypes.CAPABILITY]: [],
      [TagTypes.PRACTICE]: [],
      [TagTypes.REGION]: [],
      [TagTypes.SOURCE]: [{ id, label }],
    })
    location.hash = `#/${AppRoutes.SEARCH}/`
  }

  return (
    <Table headers={headers} cssClass={Style.table}>
      {automationList.contents?.length > 0 ? (
        automationList.contents
          .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
          .map((automation: IManageAutomationTableRow) => {
            return (
              <TableRow key={automation.Id}>
                <TableCell>{automation.Title}</TableCell>
                <TableCell>
                  <StatusIcon automation={automation} appRole={AppRolesEnum.TECHNICAL} showApproverName />
                </TableCell>
                <TableCell>
                  <StatusIcon automation={automation} appRole={AppRolesEnum.IT} showApproverName />
                </TableCell>
                <TableCell>
                  <StatusIcon automation={automation} appRole={AppRolesEnum.DIGITAL} showApproverName />
                </TableCell>
                <TableCell>
                  <Pill
                    style={{ width: 'max-content' }}
                    cssClass={Style.pill}
                    size='mini'
                    colour={13}
                    onClick={() => handlePillClick(automation.AutomationSourceId.toString())}
                  >
                    {AutomationSources.find((x) => x.key === automation.AutomationSourceId.toString())?.value}
                  </Pill>
                </TableCell>
                {isDeactivateList && <TableCell>{dateToString(automation.DeactivationDate ?? '')}</TableCell>}
                {!isDeactivateList && <TableCell>{dateToString(automation.DateCreated)}</TableCell>}
                <TableCell>
                  <OpenButton automation={automation} />
                </TableCell>
              </TableRow>
            )
          })
      ) : (
        <TableCell colSpan={7} align='right' style={{ textAlign: 'center', padding: '30px' }}>
          No available data
        </TableCell>
      )}
    </Table>
  )
}

export default ManageAutomationsTable
