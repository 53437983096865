import { atom, selectorFamily } from 'recoil'
import { ApproverModalActionTypes } from '../enums/AutomationViewer'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { getAutomation, getAutomationComments, getAutomationLikes } from '../api/AutomationService'
import { ResponseData } from '../models/api/IResponse'
import { IAutomationLike } from '../models/api/IManageAutomationTableRow'
import { IAutomationCommentResponse } from '../models/api/IAutomationCommentModel'
import { ICommentItemProps } from '../components/AutomationViewer/comments/CommentItem'

export interface IShowApproverModal {
  show: boolean
  type: number
}

export const ShowApproverModal = atom<IShowApproverModal>({
  key: 'show_approver_modal',
  default: { show: false, type: ApproverModalActionTypes.CLOSE },
})

export const ShowActionNotRequiredModal = atom<Pick<IShowApproverModal, 'show'>>({
  key: 'show_action_not_required_modal',
  default: { show: false },
})

export const CurrentAutomationType = atom<number>({
  key: 'current_automation_type',
  default: 0,
})

export const CurrentAutomationId = atom<string>({
  key: 'current_automation_id',
  default: '',
})

export const automationDetailsRefresh = atom({
  key: 'counterState',
  default: 0,
})

export const AutomationCommentsRefresh = atom({
  key: 'automation_comments_refresh',
  default: 0,
})

type AutomationDetailsParams = {
  id: string
  typeId?: number
}

type AutomationLikesParams = {
  id: string
}

type AutomationCommentsParams = {
  automationId: string
}

export const AutomationDetails = selectorFamily<IGetAutomationResponse | null, AutomationDetailsParams>({
  key: 'automation_details',
  get:
    ({ id, typeId }) =>
    async ({ get }) => {
      if (!id) return null
      get(automationDetailsRefresh)
      const results = await getAutomation({ id, typeId })
      if (!results?.success) {
        throw results
      }
      return ResponseData(results)
    },
})

export const AutomationLikes = selectorFamily<IAutomationLike[] | null, AutomationLikesParams>({
  key: 'automation_likes',
  get:
    ({ id }) =>
    async () => {
      try {
        if (!id) return null
        const results = await getAutomationLikes({ id })
        return ResponseData(results)
      } catch (error) {
        console.log(error)
        return null
      }
    },
})

export const AutomationComments = selectorFamily<IAutomationCommentResponse[] | null, AutomationCommentsParams>({
  key: 'automation_details',
  get:
    ({ automationId }) =>
    async ({ get }) => {
      try {
        if (!automationId) return null
        get(AutomationCommentsRefresh)
        const results = await getAutomationComments({ automationId })
        return ResponseData(results) as IAutomationCommentResponse[] | null
      } catch (error) {
        console.log(error)
        return null
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const AutomationCommentsAtom = atom<ICommentItemProps[]>({
  key: 'automation_comments',
  default: [],
})
