import { FC } from 'react'
import Style from '../../../styles/Comments.module.sass'
import { DATE_TIME_FORMAT_WITH_MERIDIEM, dateToString } from '../../../helpers/utils'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import CommentForm from './CommentForm'
import { IAutomationCommentResponse } from '../../../models/api/IAutomationCommentModel'
import { CommentListProps, CommentListRenderer } from './CommentList'
import Linkify from '../../common/Linkify'
import { User } from '@auth0/auth0-react'
import { useUserPermission } from '../../../hooks/useUserPermission'
import { actions } from '../../../helpers/userPermission'

export type ICommentItemProps = CommentListProps & {
  comment: IAutomationCommentResponse
  isActive?: boolean
  user?: User
  onReplyClick?: (commentId: string) => void
  onDeleteClick: (commentId: string) => void
}

const CommentItem: FC<ICommentItemProps> = ({
  automationId,
  canReply,
  comment,
  isActive,
  user,
  onReplyClick,
  onDeleteClick,
}) => {
  const canDeleteOtherComments = useUserPermission(actions.DELETE_OTHERS_COMMENT)
  const isCommentOwner = user?.email === comment.User?.UserEmail

  return (
    <div key={comment.Id} className={canReply ? Style.commentItem : Style.replyItem}>
      <div className={Style.commentHeader}>
        <div className={Style.commentIcon}>
          <span className={Style.circleIcon}>{comment.User?.UserFullName.charAt(0)}</span>
        </div>
        <div className={Style.commentAuthor}>
          <span className={Style.authorName}>{comment.User?.UserFullName}</span>
          <span className={Style.createdAt}>{dateToString(comment.CreatedAt, DATE_TIME_FORMAT_WITH_MERIDIEM)}</span>
        </div>
      </div>
      <div className={Style.commentContent}>
        <div className={Style.commentText}>
          <Linkify text={comment.CommentText} className={Style.linkified} />
        </div>
        <div className={Style.commentControls}>
          {canReply && (
            <div className={Style.actionButton} role='none' onClick={() => onReplyClick?.(comment.Id)}>
              <Icon type='reply' outlined className={Style.actionIcon} />
              <span>Reply</span>
            </div>
          )}
          {(canDeleteOtherComments || isCommentOwner) && (
            <div className={Style.actionButton} role='none' onClick={() => onDeleteClick(comment.Id)}>
              <Icon type='delete' outlined className={Style.actionIcon} />
              <span>Delete</span>
            </div>
          )}
        </div>

        <div className={Style.repliesContainer}>
          <CommentListRenderer
            items={(comment.Replies ?? []).map((r) => ({
              automationId,
              canReply: false,
              comment: r,
              user,
              onDeleteClick,
            }))}
            renderItem={CommentItem}
            limit={2}
            isReply
          />
        </div>
        {canReply && isActive && (
          <div className={Style.replyInputContainer}>
            <CommentForm automationId={automationId} isReply threadId={comment.Id} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CommentItem
