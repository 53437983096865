import React from 'react'
import { htmlEntitiesParser, indexKey, URL_MATCHER_REGEX, URL_SPLITTER_REGEX } from '../../helpers/utils'

interface LinkifyProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string
}

const Linkify: React.FC<LinkifyProps> = ({ text, className }) => {
  const parsedText = htmlEntitiesParser(text)
  if (!parsedText) {
    return <span className={className}>{text}</span>
  }

  const textParts = parsedText.split(URL_SPLITTER_REGEX)
  return (
    <div className={className}>
      {textParts.map((part) => {
        const isURL = URL_MATCHER_REGEX.test(part)
        return isURL ? (
          <a key={indexKey()} href={part} target='_blank' rel='noopener noreferrer'>
            {part}
          </a>
        ) : (
          part
        )
      })}
    </div>
  )
}

export default Linkify
