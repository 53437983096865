import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { ShowUploadSubmitModal } from '../../stores/AutomationUploadStore'
import Style from '../../styles/CommonModals.module.sass'

interface SubmitModalProps {
  onYes?: () => void
  onNo?: () => void
}

const SubmitModal: FC<SubmitModalProps> = ({ onYes, onNo }) => {
  const [showUploadSubmitModal, setShowUploadSubmitModal] = useRecoilState(ShowUploadSubmitModal)

  const handleClose = () => {
    setShowUploadSubmitModal(false)
  }

  const handleYes = () => {
    handleClose()
    onYes?.()
  }

  const handleNo = () => {
    handleClose()
    onNo?.()
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Yes',
      type: 'secondary',
      onClick: handleYes,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'No',
      onClick: handleNo,
      cssClass: Style.primaryButton,
    },
  ]

  return (
    <Modal
      isShowing={showUploadSubmitModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={Style.commonModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.headerRow}>Submit Automation?</div>
        <p className={Style.modalContent}>
          Once an Automation is submitted, Automation details can not be edited anymore. Are you sure you want to send
          the submission?
        </p>

        <p className={Style.modalContent}>
          *The submitted automation will be published following Technical, Digital and IT Authorisations. These
          authorisations will be automatically triggered upon submission.
        </p>
      </>
    </Modal>
  )
}

export default SubmitModal
