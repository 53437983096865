import { deleteAsync, getAsync, patchAsync, postAsync, postMultiFormDataAsync, putAsync } from '../helpers/apiRequest'
import {
  IAutomationSearchResponse,
  IGetAutomationRequest,
  IGetAutomationResponse,
} from '../models/api/IAutomationRequest'
import {
  IAutomationUploadRequest,
  IAutomationUploadResponse,
  IAutomationLikeUploadRequest,
  IAutomationLikeUploadResponse,
  IUpdateAutomationStatusRequest,
  IAutomationEditApproval,
} from '../models/api/IAutomationUploadRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IAutomationLike, IManageAutomationTableRow } from '../models/api/IManageAutomationTableRow'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'
import { ISearchDiscoverRequest } from '../models/api/ISearchRequest'
import { IPostUpdateApprovalRequest, IPostUpdateApprovalResponse } from '../models/api/IAutomationApprovalRequest'
import {
  IAutomationCommentRequest,
  IDeleteCommentRequest,
  IGetAutomationCommentRequest,
} from '../models/api/IAutomationCommentModel'

export const postAutomationUpload = TokenExpiryWrapper(
  async (request: IAutomationUploadRequest): Promise<IResponse<IAutomationUploadResponse>> => {
    try {
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token' && key !== 'AutomationFile')
        .forEach((key) => {
          if (key === 'AutomationClients' || key === 'CurrentActivityTime' || key === 'AutomatedActivityTime') {
            formData.append(key, JSON.stringify(request[key]))
            return
          }
          formData.append(key, request[key] || '')
        })

      if (request.AutomationFile) {
        formData.append('file', request.AutomationFile)
      }

      return postMultiFormDataAsync(`/v1/automations/upload`, request.token ?? '', formData)
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)

export const postSearchAutomation = TokenExpiryWrapper(
  async (request: ISearchDiscoverRequest): Promise<IResponse<IAutomationSearchResponse>> =>
    postAsync(`/v1/automations/search`, request),
  [],
  null,
)

export const getAutomation = TokenExpiryWrapper(
  async (request: IGetAutomationRequest): Promise<IResponse<IGetAutomationResponse>> => {
    const typeId = request.typeId ? `?typeId=${request.typeId}` : ''
    return getAsync(`/v1/automations/details/${request.id}${typeId}`, request)
  },
  [],
  null,
)

export const getManageAutomationsTableRows = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<IManageAutomationTableRow[]>> =>
    getAsync(`/v1/automations/manage-list`, request),
  [],
  null,
)

export const getMyContributions = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<IManageAutomationTableRow[]>> =>
    getAsync(`/v1/automations/my-contributions`, request),
  [],
  null,
)

export const getDeactivatedAutomations = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<IManageAutomationTableRow[]>> =>
    getAsync(`/v1/automations/deactivated-list`, request),
  [],
  null,
)

export const postlikeAutomation = TokenExpiryWrapper(
  async (request: IAutomationLikeUploadRequest): Promise<IResponse<IAutomationLikeUploadResponse>> => {
    return postAsync(`/v1/automations/likes/${request.id}`, request)
  },
  [],
  null,
)

export const checkLikeAutomation = TokenExpiryWrapper(
  async (request: IAutomationLikeUploadRequest): Promise<IResponse<boolean>> => {
    return await getAsync(`/v1/automations/${request.id}/check-Liked`, request)
  },
  [],
  null,
)
export const getAutomationLikes = TokenExpiryWrapper(
  async (request: IAutomationLikeUploadRequest): Promise<IResponse<IAutomationLike[]>> => {
    return await getAsync(`/v1/automations/all-likes/${request.id}`, request)
  },
  [],
  null,
)

export const updateApprovals = TokenExpiryWrapper(
  async (request: IPostUpdateApprovalRequest): Promise<IResponse<IPostUpdateApprovalResponse>> =>
    postAsync(`/v1/automations/approvals`, request),
  [],
  null,
)

export const deactivateAutomation = TokenExpiryWrapper(
  async (request: IUpdateAutomationStatusRequest) => patchAsync(`/v1/automations/deactivate`, request),
  [],
  null,
)

export const reactivateAutomation = TokenExpiryWrapper(
  async (request: IUpdateAutomationStatusRequest) => patchAsync(`/v1/automations/reactivate`, request),
  [],
  null,
)

export const deleteAutomation = TokenExpiryWrapper(
  async (request: IUpdateAutomationStatusRequest) => deleteAsync(`/v1/automations`, request),
  [],
  null,
)

export const editApproval = TokenExpiryWrapper(
  async (request: IAutomationEditApproval) => putAsync(`/v1/automations/approvals`, request),
  [],
  null,
)

export const addNewComment = TokenExpiryWrapper(
  async (request: IAutomationCommentRequest) => postAsync(`/v1/automations/comments`, request),
  [],
  null,
)

export const getAutomationComments = TokenExpiryWrapper(
  async (request: IGetAutomationCommentRequest) =>
    getAsync(`/v1/automations/comments/${request.automationId}`, request),
  [],
  null,
)

export const deleteAutomationComment = TokenExpiryWrapper(
  async (request: IDeleteCommentRequest) => deleteAsync(`/v1/automations/comments/${request.commentId}`, request),
  [],
  null,
)
