import { FC, useCallback, useState } from 'react'
import Style from '../../../styles/Comments.module.sass'
import { Button, FormInput, useToast } from '@aurecon-creative-technologies/styleguide'
import { addNewComment } from '../../../api/AutomationService'
import { useSetRecoilState } from 'recoil'
import { AutomationCommentsRefresh } from '../../../stores/AutomationViewerStore'
import { addNewCommentFormFields, addNewCommentFormSchema } from '../../../validators/AddNewCommentFormValidator'
import { formatValidationResult } from '../../../helpers/commonFunctions'
import classNames from 'classnames'

interface CommentFormProps {
  automationId: string
  isReply?: boolean
  threadId?: string
}

const CommentForm: FC<CommentFormProps> = (props: CommentFormProps) => {
  const { addToast } = useToast()
  const [comment, setComment] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isFormActive, setIsFormActive] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const refreshComments = useSetRecoilState(AutomationCommentsRefresh)

  const hasFormErrors = Object.keys(formErrors).length !== 0
  const onCommentValueChange = (value: string) => {
    setComment(value)
    const validationResult = addNewCommentFormSchema.validate({ comment: value }, { abortEarly: false })
    if (!validationResult.error) {
      setFormErrors({})
      return
    }

    const formattedErrors = formatValidationResult(validationResult.error)
    setFormErrors(formattedErrors ?? {})
  }

  const handlePostClick = useCallback(async () => {
    if (hasFormErrors) {
      return
    }

    const data = {
      automationId: props.automationId,
      commentText: comment,
      threadId: props.threadId,
    }

    setIsSubmitting(true)
    const response = await addNewComment(data)
    if (!response?.success) {
      addToast({
        type: 'error',
        message: 'Failed to post comment',
        timeout: 5000,
      })
      setIsSubmitting(false)
      return
    }

    addToast({
      type: 'success',
      message: 'Your comment has been posted',
      timeout: 5000,
    })
    setComment('')
    setIsSubmitting(false)
    refreshComments((n) => n + 1)
  }, [addToast, comment, hasFormErrors, props.automationId, props.threadId, refreshComments])

  const commentClasses = classNames({
    [Style.replyInput]: props.isReply,
    [Style.commentInput]: !props.isReply,
    [Style.hasError]: !!formErrors[addNewCommentFormFields.commentText],
  })

  return (
    <div className={Style.commentFormContainer}>
      <FormInput
        type='multiline'
        required
        placeholder='Enter comment here...'
        cssClass={commentClasses}
        value={comment}
        onChange={onCommentValueChange}
        onFocus={() => setIsFormActive(true)}
        onBlur={() => setIsFormActive(false)}
        multilineLimit={500}
        error={formErrors[addNewCommentFormFields.commentText]}
      />
      <div className={isFormActive ? Style.commentButtonsGreenPrimary : Style.commentButtonsSecondary}>
        <Button
          type='default'
          size='medium'
          disabled={hasFormErrors || isSubmitting || comment.length === 0}
          label={isSubmitting ? 'Posting' : 'Post'}
          cssClass={Style.post}
          onClick={handlePostClick}
        />
      </div>
    </div>
  )
}

export default CommentForm
