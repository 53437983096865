import { FC, useState } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import CookiesPolicy from './pages/CookiesPolicy'
import Login from './pages/Login'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import { useAuth0 } from '@auth0/auth0-react'
import Page from './components/Page'
import LoadingScreen from './components/LoadingScreen'
import { RecoilRoot } from 'recoil'
import Consent from './pages/Consent'
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'
import TermsCookiesModal from './components/TermsCookiesModal'
import Profile from './pages/Profile'
import Discover from './pages/Discover'
import Search from './pages/Search'
import Manage from './pages/Manage'
import AutomationViewer from './pages/AutomationViewer'
import UploadAutomation from './pages/UploadAutomation'
import { AppRoutes } from './enums/AppRoutes'
import FeaturedViewer from './pages/FeaturedViewer'
import { postRegisterUser } from './api/UserService'
import UserSetup from './components/UserSetup'
import { appInsights } from './api/AppInsights'
import ApproverManagement from './pages/ApproverManagement'
import ApplicationInsights from './pages/ApplicationInsights'
import ErrorPage from './pages/ErrorPage'
import { HttpStatusCodes } from './enums/ApiRequestConstants'

const App: FC = () => {
  const { isLoading, error, isAuthenticated, user } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)
  const [authContext, setAuthContext] = useState(false)

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    if (user) {
      console.log('Registering')
      postRegisterUser({
        userId: user.sub as string,
        name: user.name as string,
        email: user.email as string,
      })
    }

    return (
      <RecoilRoot>
        <Page>
          <LoadingScreen text='Loading application...' />
        </Page>
      </RecoilRoot>
    )
  }

  const publicRoutes = [
    <Route key='home_public' path='/login' element={<Login />} />,
    <Route key='terms' path='/termsandconditions' element={<TermsAndConditions />} />,
    <Route key='privacy' path='/privacy' element={<PrivacyPolicy />} />,
    <Route key='cookies' path='/cookies' element={<CookiesPolicy />} />,
  ]

  const privateRoutes = [
    <Route key='searchText' path='/search/:searchText' element={<Search />} />,
    <Route key='manage' path='/manage' element={<Manage />} />,
    <Route key='search' path='/search' element={<Search />} />,
    <Route key='profile' path='/profile' element={<Profile />} />,
    <Route key='discover' path='/discover' element={<Search />} />,
    <Route key='approverManagement' path='/approver-management' element={<ApproverManagement />} />,
    <Route key='applicationInsights' path='/application-insights' element={<ApplicationInsights />} />,
    <Route key='automationDetails' path='/view/:automationId' element={<AutomationViewer />}>
      <Route key='automationDetailsSL' path=':typeId' element={<AutomationViewer />} />
    </Route>,
    <Route key='featured' path='/featured' element={<FeaturedViewer maxTags={5} />}>
      <Route key='featured-id' path=':automationId' element={<FeaturedViewer maxTags={5} />} />
    </Route>,
    <Route key='main' path='/' element={<Discover />} />,
    <Route key={'upload'} path={`/${AppRoutes.UPLOAD_AUTOMATION}`} element={<UploadAutomation />}>
      <Route key={'editAutomation'} path={`:automationId`} element={<UploadAutomation />} />
    </Route>,
  ]

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter>
          <Routes>
            {publicRoutes}
            <Route path='/*' element={<Navigate to='/login' state={location} />} />
          </Routes>
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  if (!authContext && appInsights) {
    appInsights.setAuthenticatedUserContext(user?.email ?? 'unknown')
    setAuthContext(true)
  }

  // In the case with two routes with the same path, private will override public
  return (
    <RecoilRoot>
      <ToastProvider>
        <HashRouter>
          <Routes>
            {privateRoutes}
            {publicRoutes}
            <Route path='*' element={<ErrorPage errorCode={HttpStatusCodes.NOT_FOUND} />} />
          </Routes>
          <UserSetup />
          <TermsCookiesModal />
        </HashRouter>
        <Toast />
      </ToastProvider>
    </RecoilRoot>
  )
}

export default App
