export const ApproverModalActionTypes = {
  REJECT: 0,
  APPROVE: 1,
  REQUIRE_ACTION: 2,
  CLOSE: 3,
  ACCEPT_FOR_TRACKING: 4,
}

export const ApproverModalTitle = {
  [ApproverModalActionTypes.REJECT]: 'Reject Automation?',
  [ApproverModalActionTypes.APPROVE]: 'Authorise Automation?',
  [ApproverModalActionTypes.REQUIRE_ACTION]: 'Require Action?',
  [ApproverModalActionTypes.ACCEPT_FOR_TRACKING]: 'Accept for Tracking?',
}

export const ApproverModalContent = {
  [ApproverModalActionTypes.REJECT]:
    'Rejecting an automation submission can not be undone and edited. Are you sure you want to reject this Automation?',
  [ApproverModalActionTypes.APPROVE]:
    'Approving an automation submission can not be undone and edited. Are you sure you want to authorise this Automation?',
  [ApproverModalActionTypes.REQUIRE_ACTION]:
    'Requiring an action can not be undone and edited. Are you sure you want to send your requirement?',
  [ApproverModalActionTypes.ACCEPT_FOR_TRACKING]:
    'Accepting for tracking an automation submission can not be undone and edited. Are you sure you want this Automation to be accepted for tracking?',
}
