import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Button, DateInput, IDateInputDates, IHeader, useToast } from '@aurecon-creative-technologies/styleguide'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'
import { Loadable } from 'recoil'
import { IAppInsightsData } from '../../models/api/IApplicationInsights'
import { BarGraphSettings, INSIGHTS_MAX_PAGE_SIZE } from '../../config/config'
import CustomPagination from '../common/CustomPagination'
import { dateToString, filterArrayByDate, sortArray } from '../../helpers/utils'
import { ApplicationsLabel } from '../../enums/ApplicationInsightsEnums'
import AppInsightsTable from './AppInsightsTable'
import AppInsightsBarChart from './AppInsightsBarChart'
import { ResponseData } from '../../models/api/IResponse'
import { exportSubmissionsInsights } from '../../api/AppService'

interface ISubmissionsProps {
  submissionInsightsLoadable: Loadable<IAppInsightsData>
}

const TABLE_HEADER_AUTHOR = 'author'
const TABLE_HEADER_TITLE = 'title'
const TABLE_HEADER_APPLICATION = 'application'
const TABLE_HEADER_DATE = 'date'

const Submissions: React.FC<ISubmissionsProps> = (props) => {
  const { addToast, removeToast } = useToast()
  const submissionInsights = props.submissionInsightsLoadable
  const [isDownloading, setIsDownloading] = useState(false)
  const [titleSort, setTitleSort] = useState('none')
  const [authorSort, setAuthorSort] = useState('none')
  const [applicationSort, setApplicationSort] = useState('none')
  const [dateSort, setDateSort] = useState('desc')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const canDownloadInsights = useUserPermission(actions.DOWNLOAD_INSIGHTS)
  const [submissions, setSubmissions] = useState<IAppInsightsData>({
    automationCentre: [],
    scriptLibrary: [],
    totalCount: 0,
  })
  const [dateFilter, setDateFilter] = useState<IDateInputDates>({
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    if (submissionInsights.state === 'hasValue') {
      setSubmissions(submissionInsights.contents)
    }
  }, [submissionInsights])

  useEffect(() => {
    const allSubmissions = [...submissions.automationCentre, ...(submissions.scriptLibrary ?? [])]
    if (allSubmissions.length === 0) return
    const sortedDownloads = sortArray(allSubmissions, 'dateCreated', 'asc')
    setDateFilter({
      startDate: new Date(sortedDownloads[0]?.dateCreated),
      endDate: new Date(),
    })
  }, [submissions.automationCentre, submissions.scriptLibrary])

  const handleSort = useCallback((sortString: string, type: string) => {
    setTitleSort(type === TABLE_HEADER_TITLE ? sortString : 'none')
    setAuthorSort(type === TABLE_HEADER_AUTHOR ? sortString : 'none')
    setApplicationSort(type === TABLE_HEADER_APPLICATION ? sortString : 'none')
    setDateSort(type === TABLE_HEADER_DATE ? sortString : 'none')
  }, [])

  const submissionsSorted = useMemo(() => {
    const allSubmissions = [...submissions.automationCentre, ...(submissions.scriptLibrary ?? [])]
    const submissionsFilter =
      !dateFilter.startDate && !dateFilter.endDate
        ? allSubmissions
        : filterArrayByDate(allSubmissions, dateFilter, 'dateCreated')

    if (titleSort !== 'none') {
      return sortArray(submissionsFilter, 'title', titleSort)
    }
    if (authorSort !== 'none') {
      return sortArray(submissionsFilter, 'author.UserFullName', authorSort)
    }
    if (applicationSort !== 'none') {
      return sortArray(submissionsFilter, 'application', applicationSort)
    }
    if (dateSort !== 'none') {
      return sortArray(submissionsFilter, 'dateCreated', dateSort)
    }
    return submissionsFilter
  }, [
    submissions.automationCentre,
    submissions.scriptLibrary,
    dateFilter,
    titleSort,
    authorSort,
    applicationSort,
    dateSort,
  ])

  const barData = useMemo(() => {
    return [
      {
        name: ApplicationsLabel.AUTOMATIONCENTRE,
        value:
          submissionsSorted.filter((submission) => submission.application === ApplicationsLabel.AUTOMATIONCENTRE)
            .length ?? 0,
        fill: BarGraphSettings.BarGraphColours.AutomationCentre,
      },
      {
        name: ApplicationsLabel.SCRIPTLIBRARY,
        value:
          submissionsSorted.filter((submission) => submission.application === ApplicationsLabel.SCRIPTLIBRARY).length ??
          0,
        fill: BarGraphSettings.BarGraphColours.ScriptLibrary,
      },
    ]
  }, [submissionsSorted])

  const pageCount = submissionsSorted.length / INSIGHTS_MAX_PAGE_SIZE
  const maxPages = Math.ceil(pageCount > 0 ? pageCount : 1)

  const tableHeaders = [
    {
      label: 'Automation Title',
      sort: titleSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_TITLE),
    },
    {
      label: 'Author',
      sort: authorSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_AUTHOR),
    },
    {
      label: 'Application',
      sort: applicationSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_APPLICATION),
    },
    {
      label: 'Date Creation',
      sort: dateSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_DATE),
    },
  ] as IHeader[]

  const handleCSVDownload = useCallback(async () => {
    setIsDownloading(true)
    const infoToast = addToast({
      type: 'info',
      message: `Generating CSV file, please wait...`,
    })
    const filename =
      !dateFilter.startDate || !dateFilter.endDate
        ? 'Total Submissions-ALL.csv'
        : `Total Submissions-${dateToString(dateFilter.startDate, 'ddMMyyyy')} to ${dateToString(
            dateFilter.endDate,
            'ddMMyyyy',
          )}.csv`
    const csv = ResponseData(
      await exportSubmissionsInsights({
        startDate: dateFilter.startDate?.toISOString(),
        endDate: dateFilter.endDate?.toISOString(),
      }),
    )

    if (!csv) {
      removeToast(infoToast)
      addToast({
        type: 'error',
        message: `Failure to export, please try again`,
        timeout: 5000,
      })
      setIsDownloading(false)
      return
    }

    removeToast(infoToast)
    addToast({
      type: 'success',
      message: `Automation submissions have been exported as CSV file.`,
      timeout: 5000,
    })

    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    link.download = filename
    link.click()
    link.remove()
    setIsDownloading(false)
  }, [addToast, dateFilter.endDate, dateFilter.startDate, removeToast])

  return (
    <div className={PageStyle.appInsightsContainer}>
      <div className={PageStyle.appInsightsHeader}>
        <div className={PageStyle.appInsightsTitle}>
          <div className={PageStyle.title}>Submissions</div>
        </div>
        <div className={PageStyle.actionContainer}>
          <div className={PageStyle.dateRange}>
            <div className={PageStyle.dateRangeTitle}> Date Range </div>
            <DateInput dates={dateFilter} rangeType cssClass={PageStyle.dateRangeInput} onDateChange={setDateFilter} />
          </div>
          {canDownloadInsights && (
            <Button
              cssClass={PageStyle.downloadButton}
              type='icon-round'
              icon='file_download'
              onClick={handleCSVDownload}
              disabled={isDownloading || submissionsSorted.length === 0}
            />
          )}
        </div>
      </div>
      <div className={PageStyle.appInsightsContents}>
        <div className={PageStyle.appInsightsGraph}>
          <div className={PageStyle.totalCount}>{submissionsSorted.length}</div>
          <AppInsightsBarChart
            isLoading={submissionInsights.state === 'loading'}
            barData={barData}
          ></AppInsightsBarChart>
        </div>
        <div className={PageStyle.appInsightsTable}>
          <AppInsightsTable
            isLoading={submissionInsights.state === 'loading'}
            tableData={submissionsSorted}
            tableHeaders={tableHeaders}
            currentPage={currentPage}
          ></AppInsightsTable>
          <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={maxPages} />
        </div>
      </div>
    </div>
  )
}

export default Submissions
