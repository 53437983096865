import { FC, useState } from 'react'
import Style from '../../../styles/Comments.module.sass'
import { IGetAutomationResponse } from '../../../models/api/IAutomationRequest'
import { Accordion, AccordionPanel } from '@aurecon-creative-technologies/styleguide'
import CommentForm from './CommentForm'
import CommentList from './CommentList'
import { AutomationStateNames } from '../../../enums/AutomationStates'

interface CommentsProps {
  automation: IGetAutomationResponse
  isExternalSource?: boolean
}

const header = (
  <div className={Style.headingHolder}>
    <h2 className={Style.commentsTitle}>Comments</h2>
  </div>
)

const Comments: FC<CommentsProps> = (props: CommentsProps) => {
  const [open, setOpen] = useState(true)

  if (!props.isExternalSource && props.automation.AutomationState?.Name !== AutomationStateNames.APPROVED) {
    return null
  }

  return (
    <Accordion
      activePanelIds={open ? 'main' : ''}
      onPanelToggle={() => setOpen((o) => !o)}
      cssClass={Style.commentsWrapper}
    >
      <AccordionPanel
        label={header}
        panelId='main'
        cssHeaderClass={Style.accordionPanel}
        cssContentClass={Style.accordionContent}
      >
        <CommentForm automationId={props.automation.Id} />
        <CommentList automationId={props.automation.Id} canReply />
      </AccordionPanel>
    </Accordion>
  )
}

export default Comments
