import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Style from '../../../styles/Comments.module.sass'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  AutomationComments,
  AutomationCommentsAtom,
  AutomationCommentsRefresh,
} from '../../../stores/AutomationViewerStore'
import CommentItem from './CommentItem'
import { Button, useToast } from '@aurecon-creative-technologies/styleguide'
import LoadingScreen from '../../LoadingScreen'
import { REPLY_SLICE_START, THREAD_SLICE_START } from '../../../config/config'
import ConfirmModal from '../../ConfirmModal'
import { deleteAutomationComment } from '../../../api/AutomationService'
import { useAuth0 } from '@auth0/auth0-react'

export interface CommentListProps {
  automationId: string
  canReply?: boolean
}

type CommentListRendererProps<T> = {
  items: T[]
  renderItem: (item: T) => React.ReactNode
  limit?: number
  isReply?: boolean
}

export function CommentListRenderer<T>({ items, renderItem, limit, isReply }: Readonly<CommentListRendererProps<T>>) {
  const [showAll, setShowAll] = useState<boolean>()

  const slicedItems = useMemo(() => {
    return isReply ? items.slice(REPLY_SLICE_START) : items.slice(THREAD_SLICE_START, limit)
  }, [items, limit, isReply])

  const displayedItems = useMemo(() => {
    return showAll ? items : slicedItems
  }, [showAll, items, slicedItems])

  return (
    <div className={Style.listComponentWrapper}>
      {isReply && !showAll && limit && items.length > limit && (
        <div onClick={() => setShowAll(true)} className={Style.viewAllRepliesButton} role='none'>
          View all {items.length} comments
        </div>
      )}
      {displayedItems.map((item) => renderItem(item))}

      {!isReply && !showAll && limit && items.length > limit && (
        <div className={Style.viewMoreBtnContainer}>
          <Button
            type='secondary'
            label='View more comments'
            cssClass={Style.viewMoreButton}
            onClick={() => setShowAll(true)}
          />
        </div>
      )}
    </div>
  )
}

const CommentList: FC<CommentListProps> = ({ automationId, canReply }) => {
  const { user } = useAuth0()
  const { addToast } = useToast()

  const [activeItems, setActiveItems] = useState<string[]>([])
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [commentId, setCommentId] = useState<string>('')

  const automationComments = useRecoilValueLoadable(AutomationComments({ automationId }))
  const [commentData, setCommentData] = useRecoilState(AutomationCommentsAtom)
  const automationRefresh = useSetRecoilState(AutomationCommentsRefresh)

  const handleReplyClick = useCallback(
    (commentId: string) => {
      if (activeItems.includes(commentId)) {
        setActiveItems(activeItems.filter((id) => id !== commentId))
      } else {
        setActiveItems([...activeItems, commentId])
      }
    },
    [activeItems],
  )

  const handleDeleteClick = useCallback((commentId: string) => {
    setOpenConfirmModal(true)
    setCommentId(commentId)
  }, [])

  const confirmDelete = useCallback(async () => {
    if (!commentId) return
    const result = await deleteAutomationComment({ commentId })
    setOpenConfirmModal(false)
    if (!result?.success) {
      addToast({ type: 'error', message: 'Failed to delete comment', timeout: 4000 })
      return
    }

    automationRefresh((prev) => prev + 1)
    addToast({ type: 'success', message: 'Comment been deleted', timeout: 4000 })
  }, [commentId, automationRefresh, addToast])

  useEffect(() => {
    if (automationComments.state === 'hasValue') {
      setCommentData(
        automationComments.contents?.map((c) => ({
          automationId,
          canReply,
          comment: c,
          isActive: activeItems.includes(c.Id),
          user,
          onReplyClick: handleReplyClick,
          onDeleteClick: handleDeleteClick,
        })) ?? [],
      )
    }
  }, [
    automationComments,
    activeItems,
    openConfirmModal,
    automationId,
    canReply,
    setCommentData,
    handleReplyClick,
    handleDeleteClick,
    user,
  ])

  return (
    <div className={Style.commentListContainer}>
      {automationComments.state === 'hasValue' ? (
        <CommentListRenderer items={commentData} renderItem={CommentItem} limit={2} />
      ) : (
        <LoadingScreen size='small' text='Loading comments...' />
      )}

      <ConfirmModal
        title='Delete comment'
        message='This action will permanently remove the comment and its replies from the application. Are you sure you want to continue?'
        onYes={confirmDelete}
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
      />
    </div>
  )
}

export default CommentList
